<template>
  <div>
    <!-- 岗位管理 -->
    <ch-layout button page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
            :render-option="searchOption" :model="searchForm"
            :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>

      <template v-slot:button>
        <ch-button
          @click="openAdd"
        >新增</ch-button>
      </template>

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{height: '100%', size: 'mini',}">
          <template v-slot:handle="scope">
            <ch-button type="link"
              @click="openEdit(scope.row)"
            >编辑</ch-button>
            <ch-button type="link"
              @click="openWatch(scope.row)"
            >查看</ch-button>
            <ch-button type="link"
                       @click="openBind(scope.row)"
            >绑定角色</ch-button>
            <ch-button type="delete"
              @click="handleDelete(scope.row)"
            >删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
          type="old"
          ref="page" :sizeDe="20"
          :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <!-- 查询 编辑 修改 -->
    <post-modal
      type="old"
      ref="modal"
      :getListFunction="getTableList"
    ></post-modal>
  </div>
</template>
<script>
import {  handleDelete } from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";
import PostModal from "./postModal";
export default {
  components: { PostModal },
  data() {
    return {
      tableList: [],
      isTableLoading: false,
      treeSelect: {
        postremove: false, // 删除
        postedit: false, // 编辑
        postquery: false, // 查看
        postadd: false, // 新增
        postrole: false, // 绑定角色
        postcofig: false, // 配置项
        postSave: false, // 保存配置项
      },
      // 搜索相关
      searchForm: {
        name: null,
      },
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '岗位名称', prop: 'postName', showOverflowTooltip: true },
        {column: 'text', label: '角色', prop: 'roleNames', showOverflowTooltip: true},
        {column: 'text', label: '岗位人员', prop: 'staffName', showOverflowTooltip: true },
        {column: 'slot', label: '操作', slotName: 'handle', width: '240px', fixed: 'right'}
      ]
    },
    searchOption: function () {
      return [
        {type: 'input', label: '岗位名称', prop: 'name', placeholder: "岗位名称" },
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    ...mapGetters(['userInfo'])
  },
  watch: {
    userInfo: function () {
      this.getTableList(); // 刷新列表
      this.getPostOption(true)
    },
  },
  created() {
    // 获取操作显示权限
    // getTreeSelect(this.$route.name).then((res) => {
    //   this.treeSelect = res
    // })
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    ...mapActions({
      getPostOption: 'system/getPostOption',
    }),
    // 查询
    searchHandle() {
      this.getTableList(this.searchForm)
    },
    // 列表数据
    getTableList(searchForm=this.searchForm) {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/post/list', searchForm).then(res => {
        this.tableList = res.rows;
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },
    // 打开查看
    openWatch(row) {
      this.$refs.modal?.openWatch(row)
    },
    // 打开增加
    openAdd(){
      this.$refs.modal?.openAdd()
    },
    // 打开修改
    openEdit(row) {
      this.$refs.modal?.openEdit(row)
    },
    // 打开绑定角色
    openBind(row) {
      this.$refs.modal?.openBind(row)
    },
    handleDelete(row) {
      handleDelete("/hm/post/", row.id).then(() => {
        this.getTableList()
        this.getPostOption(true)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
